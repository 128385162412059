var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contain-box"},[_c('div',{staticClass:"contain"},[_c('el-table',{ref:"singleTable",staticClass:"Table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"highlight-current-row":"","header-cell-style":{
        'font-size': '15px',
        color: '#666666',
        'font-weight': 'bold',
        background: '#F7F7F7',
      },"row-style":{
        'font-size': '15px',
        color: '#222222',
        'font-weight': '400',
      }}},[_c('el-table-column',{attrs:{"prop":"title","label":"标题"}}),_c('el-table-column',{attrs:{"prop":"created_at","label":"提交时间"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_vm._v(" "+_vm._s(_vm._f("dataformat")(row.created_at))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"content","label":"内容","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_c('div',{staticStyle:{"overflow":"hidden","max-height":"50px"},domProps:{"innerHTML":_vm._s(row.content)}})]}}])}),_c('el-table-column',{attrs:{"prop":"reply_content","label":"评语"}}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"item-right"},[_c('div',{staticClass:"bt",on:{"click":function($event){return _vm.checkbt(scope.row)}}},[_vm._v("查看")])])]}}])})],1),_c('div',{staticClass:"elpagination"},[_c('div',{staticClass:"elpagination-title"},[_vm._v("共"+_vm._s(_vm.total)+"条")]),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.total,"page-size":10,"current-page":_vm.queryParams.page},on:{"current-change":_vm.changePage}})],1)],1),_c('Customdialog',{ref:"customdialog",attrs:{"type":"max","width":"40%","title":"日志查看","showclose":true}},[_c('div',{staticClass:"dialogbody",attrs:{"slot":"dialogbody"},slot:"dialogbody"},[_c('Loglook',{ref:"loglook"})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }